
import TheCheckItem from '@/pages/setting/components/TheCheckItem.vue'
import { defineComponent, shallowRef, watch } from 'vue'

export default defineComponent({
  name: 'TheCodeSwichGroup',
  components: {
    TheCheckItem,
  },
  emits: ['change'],
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  setup (props, ctx) {
    const codeList = shallowRef([{
      code: '',
      label: 'plstats_7',
      active: true,
    }, {
      code: 'USDX',
      label: 'USDX',
      active: false,
    }, {
      code: 'BTCUSD',
      label: 'BTC',
      active: false,
    }, {
      code: 'USOIL',
      label: 'USOIL',
      active: false,
    }, {
      code: 'XAUUSD',
      label: 'XAUUSD',
      active: false,
    }, {
      code: 'SPX500',
      label: 'S&P 500',
      active: false,
    }])

    const itemClick = (index: number) => {
      codeList.value = codeList.value.map(item => {
        item.active = false
        return item
      })
      codeList.value[index].active = true
      ctx.emit('change', codeList.value[index].code)
    }

    watch(() => props.activeIndex, () => {
      itemClick(0)
    })
    return {
      codeList,
      itemClick,
    }
  },
})
