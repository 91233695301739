
import { defineComponent, shallowRef, watch } from 'vue'
import HoldOn from '@/provider/HoldOn.vue'
import Nullable from '@/components/Nullable.vue'
import Pic from '@/components/Pic.vue'
import { div, mul } from 'essential/tools/math'
import { BestInvestment } from '@/pages/statement/incomeStatement.api'
import * as R from 'ramda'

export default defineComponent({
  name: 'TheInvestment',
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  components: {
    HoldOn,
    Nullable,
    Pic,
  },
  setup (props) {
    const listData = shallowRef<BestInvestment[]>([])
    watch(() => props.list, () => {
      const tempList = props.list as BestInvestment[] || []

      if (tempList.length === 0) {
        return
      }

      const diff = (a: BestInvestment, b: BestInvestment) => {
        return Math.abs(b.rate) - Math.abs(a.rate)
      }
      const maxRate = R.sort(diff, tempList)[0]

      listData.value = tempList.map(item => {
        let p:string|number = mul(div(Math.abs(item.rate), Math.abs(maxRate.rate)), 100).toFixed(2)
        p = +p > 100 ? '100.00' : p
        return {
          code: item.code,
          rate: item.rate,
          rank: item.rank,
          persent: p,
        }
      })
    }, {
      deep: true,
      immediate: true,
    })
    return {
      listData,
      mul,
    }
  },
})
