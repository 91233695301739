<template>
  <div>
    <div class="df-middle df-between"
      style="height: 40px; border-bottom:1px solid var(--color-border);">
      <t as="p" path="plstats_9" class="f-bold">Calendar</t>
      <div class="swich df-middle f-md">
        <t as="p" path="plstats_10"
          class="df-middle mr-16"
          :class="{'active': activeIndex===0}"
          @click="activeIndex=0">Profit / Loss ($)</t>
        <t as="p" path="plstats_11"
          class="df-middle"
          :class="{'active': activeIndex===1}"
          @click="activeIndex=1">Profit / Loss (%)</t>
      </div>
    </div>
    <table>
      <thead class="c-head" style="height: 48px;">
        <tr class="df-middle df-between c-tip f-md" style="height: 40px;">
          <th class="c-item"><t path="plstats_12">Mon.</t></th>
          <th class="c-item"><t path="plstats_13">Tues.</t></th>
          <th class="c-item"><t path="plstats_14">Wed.</t></th>
          <th class="c-item"><t path="plstats_15">Thur.</t></th>
          <th class="c-item"><t path="plstats_16">Fri.</t></th>
          <th class="c-item"><t path="plstats_17">Sat.</t></th>
          <th class="c-item"><t path="plstats_18">Sun.</t></th>
        </tr>
      </thead>
      <tbody class="c-body">
        <HoldOn :content="calendarList" :height="120">
        <tr class="df-middle df-between mb-8" v-for="(pItem, pIndex) in calendarList" :key="pIndex">
          <td class="c-item" style="height: 42px;" v-for="(item, index) in pItem" :key="index">
            <div class="i-b py-4 normal df-col df-middle br-lg"
              v-if="item.issue!=='empty'"
              :class="{'c-title zero': item.profitLoss==0,
              'success':  item.profitLoss>0,
              'danger':  item.profitLoss<0}">
              <p class="f-bold c-black">{{item.issue}}</p>
              <span class="f-sm"
                :class="{'c-success': item.profitLoss>0,
                'c-danger': item.profitLoss<0}"
                v-if="item.rate!==null">
                {{ activeIndex===0?(item.profitLoss>0?'+'+item.profitLoss:item.profitLoss):(item.profitLoss>0?'+'+item.rate:item.rate)+'%' }}
              </span>
            </div>
          </td>
        </tr>
        </HoldOn>
      </tbody>
    </table>
  </div>
</template>

<script lang='ts'>
import { defineComponent, shallowRef, watch } from 'vue'
import { getEarningCalendar, CalendarItem } from '@/pages/statement/incomeStatement.api'
import * as R from 'ramda'
import { mul } from 'essential/tools/math'
import HoldOn from '@/provider/HoldOn.vue'

export default defineComponent({
  name: 'TheCalendar',
  components: {
    HoldOn,
  },
  props: {
    dateIndex: {
      type: Number,
      required: true,
    },
  },
  setup (props) {
    const activeIndex = shallowRef(0)
    const emptyHead = shallowRef() // 日历头部填充空白
    const emptyEnd = shallowRef() // 日历尾部填充空白
    const dateList = shallowRef() // 日历主体部分
    const profitLoss = shallowRef() // 服务端返回对应日历数据
    const calendarList = shallowRef() // 整合数据

    const getCalendar = (index: number) => {
      calendarList.value = []
      getEarningCalendar({
        pageNumber: index,
      }).then(res => {
        profitLoss.value = res.calendar || []
        calendarInit(res.issue)
      })
    }

    watch(() => props.dateIndex, () => {
      getCalendar(props.dateIndex)
    }, {
      immediate: true,
    })

    const calendarInit = (date: string) => {
      const y = new Date(date).getFullYear()
      const m = new Date(date).getMonth()

      const dayNumber = new Date(y, m + 1, 0).getDate() // 获取当月总天数

      const hNum = 7 - [1, 7, 6, 5, 4, 3, 2][new Date(y, m, 1).getDay()] // 计算日历开头空格数量
      emptyHead.value = R.repeat({
        issue: 'empty',
      }, hNum)

      let eNum = 7 - ((dayNumber + hNum) % 7)
      eNum = eNum === 7 ? 0 : eNum // 计算日历末尾空格数量
      emptyEnd.value = R.repeat({
        issue: 'empty',
      }, eNum)

      // 当月天数 （号）
      dateList.value = R.times((i: number) => {
        return {
          issue: (i + 1).toString(),
          rate: null,
        } as CalendarItem & { dir: number }
      }, dayNumber)

      composeParams()
    }

    // 结合服务端数据组合渲染日历数据
    const composeParams = () => {
      for (let i = 0; i < profitLoss.value.length; i++) {
        const item = profitLoss.value[i] as CalendarItem
        dateList.value[i].rate = mul(item.rate as number, 100).toFixed(1)
        dateList.value[i].profitLoss = Math.abs(item.profitLoss) < 1 ? item.profitLoss.toFixed(2) : item.profitLoss.toFixed(0)
      }

      calendarList.value = R.splitEvery(7, R.concat(emptyHead.value, R.concat(dateList.value, emptyEnd.value)))
    }

    return {
      activeIndex,
      calendarList,
    }
  },
})
</script>

<style scoped lang='scss'>
.c-head{
  th{
    flex: 1;
    font-weight: 400;
  }
}
.c-body{
  td{
    flex: 1;
  }
}
.c-item{
  max-width: calc(calc(100vw - 80px) / 7);
  min-width: calc(calc(100vw - 80px) / 7);
  .i-b{
    height: 100%;
    width: 100%;
  }
  .normal{
    background: var(--color-background);
  }
  .success{
    background-color: #DAF6E6;
  }
  .danger{
    background-color: #FDDFDC;
  }
  .zero{
    background-color: #CFD8E3;
  }
}
.swich{
  height: 100%;
  p{
    height: 100%;
    color: var(--color-title);
    &.active{
      color: var(--color-primary);
      font-weight: bold;
    }
  }
}
</style>
