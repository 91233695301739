<template>
  <t path="plstats_6" class="c-title f-md">Compare to</t>
  <div class="d-f group mt-16">
    <div class="g-item mb-8" v-for="(item, index) in codeList" :key="index">
      <TheCheckItem
        class="mr-24" :active="item.active" @click="itemClick(index)">
        <t as="p" :path="item.label" style="white-space:nowrap;">{{ item.label }}</t>
      </TheCheckItem>
    </div>
  </div>
</template>

<script lang='ts'>
import TheCheckItem from '@/pages/setting/components/TheCheckItem.vue'
import { defineComponent, shallowRef, watch } from 'vue'

export default defineComponent({
  name: 'TheCodeSwichGroup',
  components: {
    TheCheckItem,
  },
  emits: ['change'],
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  setup (props, ctx) {
    const codeList = shallowRef([{
      code: '',
      label: 'plstats_7',
      active: true,
    }, {
      code: 'USDX',
      label: 'USDX',
      active: false,
    }, {
      code: 'BTCUSD',
      label: 'BTC',
      active: false,
    }, {
      code: 'USOIL',
      label: 'USOIL',
      active: false,
    }, {
      code: 'XAUUSD',
      label: 'XAUUSD',
      active: false,
    }, {
      code: 'SPX500',
      label: 'S&P 500',
      active: false,
    }])

    const itemClick = (index: number) => {
      codeList.value = codeList.value.map(item => {
        item.active = false
        return item
      })
      codeList.value[index].active = true
      ctx.emit('change', codeList.value[index].code)
    }

    watch(() => props.activeIndex, () => {
      itemClick(0)
    })
    return {
      codeList,
      itemClick,
    }
  },
})
</script>

<style scoped lang='scss'>
.group{
  display: flex;
  flex-wrap: wrap;
  .g-item{
    min-width: 33.33%;
    max-width: 33.33%;
  }
}
</style>
