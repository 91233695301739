<template>
  <div>
    <t path="plstats_4" as="p" class="f-bold mt-16" style="height: 40px; line-height: 40px;">
      Trends
    </t>

    <div ref="chartEle" class="chart mb-8 p-r">
      <div class="p-a df-middle"
        style="top: 0; right: 0;height:40px;padding-right: 40px;">
        <div style="border-bottom: 2px solid #326BFE; width:12px"></div>
        <t class="ml-8 f-md c-title" path="plstats_5">Me</t>
        <div v-if="compareCode" class="ml-24"
          style="border-bottom: 2px solid #F07B00; width:12px"></div>
        <span v-if="compareCode" class="ml-8 f-md c-title">{{ compareCode }}</span>
      </div>
    </div>
    <TheCodeSwichGroup @change="handleChange" :active-index="clear" />
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, shallowRef, watch } from 'vue'
import createTrendsChart from '@/pages/statement/components/trendsChart'
import { readRateByCode, MonthTrend } from '@/pages/statement/incomeStatement.api'
import TheCodeSwichGroup from '@/pages/statement/components/TheCodeSwichGroup.vue'

type IThisMonthData = {
  issue: string;
  productTrends: MonthTrend[];
  xCoordinate: string[]
}
export default defineComponent({
  name: 'TheTrendsChart',
  components: {
    TheCodeSwichGroup,
  },
  props: {
    data: Object,
    dateIndex: {
      type: Number,
      required: true,
    },
    compareClear: Number,
  },
  setup (props) {
    const chartEle = shallowRef()
    const chart = shallowRef()
    const dIndex = shallowRef(props.dateIndex)
    const clear = shallowRef()
    const compareCode = shallowRef('')
    const data = shallowRef<IThisMonthData>({
      issue: '',
      productTrends: [],
      xCoordinate: [],
    })

    watch(() => props.data, () => {
      data.value = props.data as IThisMonthData
      if (chartEle.value && chart.value) {
        chart.value.resetCtx()
        chart.value.setData(data.value || [])
      }
    }, {
      deep: true,
      immediate: true,
    })

    watch(() => props.dateIndex, () => {
      dIndex.value = props.dateIndex
    })

    watch(() => props.compareClear, () => {
      clear.value = props.compareClear
    })

    onMounted(() => {
      chart.value = createTrendsChart({
        container: chartEle.value,
      })
    })

    const handleChange = (code: string) => {
      compareCode.value = code
      chart.value.resetCtx()
      chart.value.setData(data.value || [])
      if (code) {
        readRateByCode({
          pageNumber: dIndex.value,
          code: code,
        }).then(res => {
          chart.value.setData({
            productTrends: res.productTrends || [],
            xCoordinate: res.xCoordinate || [],
          }, true)
        })
      }
    }

    return {
      chartEle,
      clear,
      compareCode,
      handleChange,
    }
  },
})
</script>

<style scoped lang='scss'>
.chart{
  width: 100%;
  height: 200px;
}
</style>
