import * as R from 'ramda'
import { sub, div, mul } from 'essential/tools/math'
import { MonthTrend } from '@/pages/statement/incomeStatement.api'

export interface QuoteObject {
  productTrends: MonthTrend[];
  xCoordinate: string[]
}

export interface Point {
  x: number;
  y: number;
}

export interface ChartOption {
  container: HTMLElement;
}

class Chart {
  private container
  private canvas: HTMLCanvasElement
  private ctx: CanvasRenderingContext2D
  private ratio: number
  private width: number
  private height: number
  private colors: string[]
  constructor (options: ChartOption) {
    // init
    this.container = Chart.getContainer(options.container)
    this.canvas = Chart.createCanvas()
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D //  this.getContext('2d')
    this.ratio = window.devicePixelRatio || 2
    this.width = 0
    this.height = 0
    this.colors = ['#326BFE', '#F07B00']
    this.resize()
    this.append()
  }

  initAxis (vals: (string | number)[]) {
    const ySpacing = (this.height - 72) / (vals.length - 1)
    for (let i = 0; i < vals.length; i++) {
      this.ctx.lineWidth = 0.9
      this.ctx.beginPath()
      this.ctx.moveTo(50, 40 + i * ySpacing)
      this.ctx.lineTo(this.width - 50, 40 + i * ySpacing)
      this.ctx.strokeStyle = '#ECEFF4'
      this.ctx.stroke()
    }
  }

  setYAxisVals (vals: (string | number)[], left?: boolean) {
    const ySpacing = (this.height - 72) / (vals.length - 1)
    for (let i = 0; i < vals.length; i++) {
      const x = left ? this.width - 50 : 0
      this.ctx.font = '12px solid'
      this.ctx.fillStyle = left ? this.colors[1] : this.colors[0]
      this.ctx.fillText((vals[i] as number).toFixed(2) + '%', (vals[i] >= 0 ? x + 5 : x), 45 + i * ySpacing)
    }
  }

  setXAxisVals (vals: string[]) {
    const s = div(this.width - 100, vals.length - 1)
    for (let i = 0; i < vals.length; i++) {
      this.ctx.font = '11px solid'
      this.ctx.fillStyle = '#5C6E7B'
      this.ctx.fillText(vals[i], 40 + i * s, 190)
    }
  }

  drawLine (points: Point[], left?: boolean) {
    if (points.length === 0) return
    this.ctx.lineWidth = 2
    this.ctx.beginPath()
    this.ctx.moveTo(points[0].x, points[0].y)
    for (let i = 0; i < points.length; i++) {
      this.ctx.lineTo(points[i].x, points[i].y)
      this.ctx.strokeStyle = left ? this.colors[1] : this.colors[0]
      this.ctx.stroke()
    }
  }

  static getContainer (c: HTMLElement) {
    return R.ifElse(R.is(String), document.querySelector, R.identity)(c)
  }

  static createCanvas () {
    return document.createElement('canvas')
  }

  getContext (id: string) {
    return this.canvas.getContext(id)
  }

  resize () {
    const { width, height } = this.container.getBoundingClientRect()
    if (width * height === 0) return

    this.height = height
    this.width = width

    const { ratio, canvas, ctx } = this

    canvas.height = height * ratio
    canvas.width = width * ratio
    canvas.style.height = `${height}px`
    canvas.style.width = `${width}px`
    ctx.scale(ratio, ratio)
  }

  append () {
    this.container.appendChild(this.canvas)
  }

  resetCtx () {
    this.ctx.clearRect(0, 0, this.width, this.height)
  }

  setData (data: QuoteObject, left?: boolean) {
    const pointSize = data.productTrends.length
    const xSize = data.xCoordinate.length
    if (pointSize && xSize) {
      // const first = R.head(data.productTrends)
      // const [high, low] = R.reduce(
      //   ([max, min], value) => [R.max(max, parseFloat((value.rate) as string)), R.min(min, parseFloat((value.rate) as string))],
      //   [parseFloat((first ? first.rate : '0') as string), parseFloat((first ? first.rate : '0') as string)],
      //   data.productTrends,
      // )
      data.productTrends = data.productTrends.map(item => {
        return {
          issue: item.issue,
          rate: item.rate || null,
        }
      })
      const diff = (a: MonthTrend, b: MonthTrend) => {
        return +(a.rate || 0) - +(b.rate || 0)
      }

      const sortByRate = R.sort(diff, data.productTrends)

      const low = parseFloat((sortByRate[0].rate || 0) as string)
      const high = parseFloat((sortByRate[sortByRate.length - 1].rate || 0) as string)

      const s = div(this.width - 110, pointSize - 1)
      const yvals = []
      const points = []
      const t = (high + (high === 0 ? 0.1 : mul(Math.abs(high), 0.1))) || 0.1
      const b = (low - (low === 0 ? 0.1 : mul(Math.abs(low), 0.1))) || -0.1
      const long = sub(t, b)
      for (let i = 0; i < data.productTrends.length; i++) {
        if (data.productTrends[i].rate !== null && data.productTrends[i].rate !== undefined) {
          points.push({
            x: 60 + i * s,
            y: mul(div(sub(t, data.productTrends[i].rate || 0), long), (this.height - 72)) + 40,
          })
        }
      }

      for (let i = 0; i < 5; i++) {
        yvals.push(mul((b + i * div(long, 4)), 100))
      }

      this.setYAxisVals(yvals.reverse(), left)
      if (!left) {
        this.initAxis(yvals)
        this.setXAxisVals(data.xCoordinate)
      }

      this.drawLine(points, left)
    }
  }

  remove () {
    this.container.removeChild(this.canvas)
  }
}

export default (options: ChartOption) => new Chart(options)
