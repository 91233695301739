<template>
  <PageWithHeader>
    <template #title>
      <t path="trade_39">Income Statement</t>
    </template>
    <div class="px-16 pb-32">
      <TheStatementDate @change="handleDateChange" />
      <t as="p" path="plstats_20" :args="{date: endDate}" class="c-tip f-sm mt-8 mb-16">Data ended by {{ endDate }}</t>
      <div class="total mt-16 py-16 br-lg">
        <div class="mb-16">
          <div class="df-col df-middle">
            <p class="c-title f-md top-item">
              <t path="plstats_2">Profit / Loss ($)</t><br>
              <span class="f-bold f-xxl c-text"
                :class="{'c-success': monthProfitLoss>0,'c-danger': monthProfitLoss<0}">
                {{monthProfitLoss>0?'+':''}}<money :v="monthProfitLoss" />
              </span>
            </p>
          </div>
        </div>
        <div class="df-center df-between">
          <div class="df-center flex-1" style="border-right: 1px solid var(--color-border);">
            <p class="c-title f-md top-item">
              <t path="plstats_3">Profit / Loss (%)</t><br>
              <span class="f-bold f-xxl c-text"
                :class="{'c-success': monthProfitLossRate>0,'c-danger': monthProfitLossRate<0}">
                {{monthProfitLossRate>0?'+':''}}{{monthProfitLossRate + '%'}}
              </span>
            </p>
          </div>
          <div class="df-center flex-1">
            <t as="p" path="plstats_19" class="c-title f-md top-item" custom="number" v-if="apiLoaded">
              <template #number>
                <br/>
                <span class="f-bold f-xxl c-text"
                  :class="{'c-success': defeatRate>0,'c-danger': defeatRate<0}">
                  {{ defeatRate }}%
                </span>
              </template>
            </t>
          </div>
        </div>
      </div>
      <TheTrendsChart :compare-clear="initSwichGrop" :data="thisMonthData" :dateIndex="dateIndex" />
      <TheInvestment :list="bestInvestment" />
      <TheCalendar :dateIndex="dateIndex" />
    </div>
  </PageWithHeader>
</template>
<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheCalendar from '@/pages/statement/components/TheCalendar.vue'
import { getEarningInfo, BestInvestment } from '@/pages/statement/incomeStatement.api'
import TheStatementDate from '@/pages/statement/components/TheStatementDate.vue'
import TheInvestment from '@/pages/statement/components/TheInvestment.vue'
import TheTrendsChart from '@/pages/statement/components/TheTrendsChart.vue'
import { mul } from 'essential/tools/math'

export default defineComponent({
  name: 'IncomeStatement',
  components: {
    PageWithHeader,
    TheCalendar,
    TheStatementDate,
    TheInvestment,
    TheTrendsChart,
  },
  setup () {
    const dateIndex = shallowRef(0) // thisMonth 0; last -1 ...
    const earningInfo = shallowRef()
    const monthProfitLoss = shallowRef(0)
    const monthProfitLossRate = shallowRef(0)
    const defeatRate = shallowRef(0)
    const bestInvestment = shallowRef<BestInvestment[]>([])
    const thisMonthData = shallowRef()
    const endDate = new Date().toUTCString()

    const initSwichGrop = shallowRef(0)

    const apiLoaded = shallowRef(false)

    const getInfo = () => {
      apiLoaded.value = false
      getEarningInfo({
        pageNumber: dateIndex.value,
      }).then(res => {
        earningInfo.value = res
        monthProfitLoss.value = res.monthProfitLoss || 0
        monthProfitLossRate.value = mul(res.monthProfitLossRate || 0, 100)
        defeatRate.value = mul(res.defeatRate || 0, 100)
        bestInvestment.value = res.bestInvestment

        thisMonthData.value = {
          issue: res.issue,
          productTrends: res.monthTrends || [],
          xCoordinate: res.xCoordinate || [],
        }

        apiLoaded.value = true
      })
    }

    getInfo()

    const handleDateChange = (e: number) => {
      dateIndex.value = e
      getInfo()

      initSwichGrop.value = new Date().valueOf()
    }
    return {
      dateIndex,
      earningInfo,
      monthProfitLoss,
      monthProfitLossRate,
      bestInvestment,
      thisMonthData,
      initSwichGrop,
      defeatRate,
      endDate,
      apiLoaded,
      handleDateChange,
    }
  },
})
</script>

<style scoped>
.total{
  background: var(--color-background);
}
.top-item{
  line-height: 20px;
  text-align: center;
}
</style>
