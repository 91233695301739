<template>
  <div>
    <t path="plstats_8" as="p" class="f-bold my-16" style="height: 40px; line-height: 40px;">
      Best Investment
    </t>
    <HoldOn :content="listData" :height="120">
      <Nullable :content="listData">
        <div>
          <div class="mb-16 df-middle" v-for="(item, index) in listData" :key="index">
            <Pic :src="`trade/No.index_${index}`" height="24" width="24" />
            <div class="persent flex-1 mx-8 br-sm">
              <div class="line df-middle br-sm px-8 c-white f-bold f-md"
                :class="{'bg-success': item.rate>=0, 'bg-danger': item.rate < 0}"
                :style="`width:${item.persent}%`"
                >
                {{ item.code }}
              </div>
            </div>
            <p :class="{'c-danger': item.rate<0, 'c-success': item.rate>=0}"
              style="max-width: 64px;min-width: 64px;text-align: right;">
              {{item.rate>0?'+':''}}{{ mul(item.rate || 0, 100).toFixed(2) }}%
            </p>
          </div>
        </div>
      </Nullable>
    </HoldOn>
  </div>
</template>

<script lang='ts'>
import { defineComponent, shallowRef, watch } from 'vue'
import HoldOn from '@/provider/HoldOn.vue'
import Nullable from '@/components/Nullable.vue'
import Pic from '@/components/Pic.vue'
import { div, mul } from 'essential/tools/math'
import { BestInvestment } from '@/pages/statement/incomeStatement.api'
import * as R from 'ramda'

export default defineComponent({
  name: 'TheInvestment',
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  components: {
    HoldOn,
    Nullable,
    Pic,
  },
  setup (props) {
    const listData = shallowRef<BestInvestment[]>([])
    watch(() => props.list, () => {
      const tempList = props.list as BestInvestment[] || []

      if (tempList.length === 0) {
        return
      }

      const diff = (a: BestInvestment, b: BestInvestment) => {
        return Math.abs(b.rate) - Math.abs(a.rate)
      }
      const maxRate = R.sort(diff, tempList)[0]

      listData.value = tempList.map(item => {
        let p:string|number = mul(div(Math.abs(item.rate), Math.abs(maxRate.rate)), 100).toFixed(2)
        p = +p > 100 ? '100.00' : p
        return {
          code: item.code,
          rate: item.rate,
          rank: item.rank,
          persent: p,
        }
      })
    }, {
      deep: true,
      immediate: true,
    })
    return {
      listData,
      mul,
    }
  },
})
</script>

<style scoped lang='scss'>
.persent{
  height: 24px;
  background-color: var(--color-light);
  .line{
    height: 24px;
  }
}
</style>
