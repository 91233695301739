
import { defineComponent, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheCalendar from '@/pages/statement/components/TheCalendar.vue'
import { getEarningInfo, BestInvestment } from '@/pages/statement/incomeStatement.api'
import TheStatementDate from '@/pages/statement/components/TheStatementDate.vue'
import TheInvestment from '@/pages/statement/components/TheInvestment.vue'
import TheTrendsChart from '@/pages/statement/components/TheTrendsChart.vue'
import { mul } from 'essential/tools/math'

export default defineComponent({
  name: 'IncomeStatement',
  components: {
    PageWithHeader,
    TheCalendar,
    TheStatementDate,
    TheInvestment,
    TheTrendsChart,
  },
  setup () {
    const dateIndex = shallowRef(0) // thisMonth 0; last -1 ...
    const earningInfo = shallowRef()
    const monthProfitLoss = shallowRef(0)
    const monthProfitLossRate = shallowRef(0)
    const defeatRate = shallowRef(0)
    const bestInvestment = shallowRef<BestInvestment[]>([])
    const thisMonthData = shallowRef()
    const endDate = new Date().toUTCString()

    const initSwichGrop = shallowRef(0)

    const apiLoaded = shallowRef(false)

    const getInfo = () => {
      apiLoaded.value = false
      getEarningInfo({
        pageNumber: dateIndex.value,
      }).then(res => {
        earningInfo.value = res
        monthProfitLoss.value = res.monthProfitLoss || 0
        monthProfitLossRate.value = mul(res.monthProfitLossRate || 0, 100)
        defeatRate.value = mul(res.defeatRate || 0, 100)
        bestInvestment.value = res.bestInvestment

        thisMonthData.value = {
          issue: res.issue,
          productTrends: res.monthTrends || [],
          xCoordinate: res.xCoordinate || [],
        }

        apiLoaded.value = true
      })
    }

    getInfo()

    const handleDateChange = (e: number) => {
      dateIndex.value = e
      getInfo()

      initSwichGrop.value = new Date().valueOf()
    }
    return {
      dateIndex,
      earningInfo,
      monthProfitLoss,
      monthProfitLossRate,
      bestInvestment,
      thisMonthData,
      initSwichGrop,
      defeatRate,
      endDate,
      apiLoaded,
      handleDateChange,
    }
  },
})
