
import { defineComponent, shallowRef, watch } from 'vue'
import { getEarningCalendar, CalendarItem } from '@/pages/statement/incomeStatement.api'
import * as R from 'ramda'
import { mul } from 'essential/tools/math'
import HoldOn from '@/provider/HoldOn.vue'

export default defineComponent({
  name: 'TheCalendar',
  components: {
    HoldOn,
  },
  props: {
    dateIndex: {
      type: Number,
      required: true,
    },
  },
  setup (props) {
    const activeIndex = shallowRef(0)
    const emptyHead = shallowRef() // 日历头部填充空白
    const emptyEnd = shallowRef() // 日历尾部填充空白
    const dateList = shallowRef() // 日历主体部分
    const profitLoss = shallowRef() // 服务端返回对应日历数据
    const calendarList = shallowRef() // 整合数据

    const getCalendar = (index: number) => {
      calendarList.value = []
      getEarningCalendar({
        pageNumber: index,
      }).then(res => {
        profitLoss.value = res.calendar || []
        calendarInit(res.issue)
      })
    }

    watch(() => props.dateIndex, () => {
      getCalendar(props.dateIndex)
    }, {
      immediate: true,
    })

    const calendarInit = (date: string) => {
      const y = new Date(date).getFullYear()
      const m = new Date(date).getMonth()

      const dayNumber = new Date(y, m + 1, 0).getDate() // 获取当月总天数

      const hNum = 7 - [1, 7, 6, 5, 4, 3, 2][new Date(y, m, 1).getDay()] // 计算日历开头空格数量
      emptyHead.value = R.repeat({
        issue: 'empty',
      }, hNum)

      let eNum = 7 - ((dayNumber + hNum) % 7)
      eNum = eNum === 7 ? 0 : eNum // 计算日历末尾空格数量
      emptyEnd.value = R.repeat({
        issue: 'empty',
      }, eNum)

      // 当月天数 （号）
      dateList.value = R.times((i: number) => {
        return {
          issue: (i + 1).toString(),
          rate: null,
        } as CalendarItem & { dir: number }
      }, dayNumber)

      composeParams()
    }

    // 结合服务端数据组合渲染日历数据
    const composeParams = () => {
      for (let i = 0; i < profitLoss.value.length; i++) {
        const item = profitLoss.value[i] as CalendarItem
        dateList.value[i].rate = mul(item.rate as number, 100).toFixed(1)
        dateList.value[i].profitLoss = Math.abs(item.profitLoss) < 1 ? item.profitLoss.toFixed(2) : item.profitLoss.toFixed(0)
      }

      calendarList.value = R.splitEvery(7, R.concat(emptyHead.value, R.concat(dateList.value, emptyEnd.value)))
    }

    return {
      activeIndex,
      calendarList,
    }
  },
})
