import requestResult from '@/common/request/request'

export type MonthTrend = {
  issue: string;
  rate: number | string | null;
}

export type BestInvestment = {
  code: string;
  rate: number;
  rank: number;
  persent?: number | string;
}

export type CalendarItem = {
  issue: string;
  rate: number | null;
  profitLoss: number;
}

export type EarningInfo = {
  issue: string;
  monthProfitLoss: number;
  monthProfitLossRate: number;
  monthTrends: MonthTrend[];
  bestInvestment: BestInvestment[];
  calendar: CalendarItem[];
  xCoordinate: string[]
  defeatRate: number;
}

export const getEarningInfo = requestResult<EarningInfo>('/api/user/earnings/info')

export const getEarningCalendar = requestResult<{issue: string;calendar: CalendarItem[]}>('/api/user/earnings/profitLoss')

export const readRateByCode = requestResult<{productTrends: MonthTrend[], xCoordinate: string[]}>('/api/user/earnings/contactCode')
